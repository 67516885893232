.PlaylistComposer div {
    background-color: #222;
    display: flex;
    flex-direction: column;
    height: 90vh;

    .list {
        flex: 1 1 auto;
        align-items: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .list::-webkit-scrollbar {
        width: 16px;
    }

    .list::-webkit-scrollbar-thumb {
        background-color: #4cc9ff;
        border-radius: 4px;
    }

    .list::-webkit-scrollbar-track {
        background-color: #323232;
        border-radius: 4px;
    }

    .list .list-item {
        height: fit-content;
        display: block;
        margin: 4px;
        margin-left: 8px;
        width: 100%;
        color: #ccc;
    }
}